<script>
export default {
  name: 'ArchivedJobsTour',
  data() {
    return {
      archivedJobsTourSteps: [
        {
          target: window.innerWidth > 1199 ? '.vs-sidebar-item-active' : '.navbar-custom',
          header: {
            title: `<strong>This is the Archived Jobs Tab</strong>`,
          },
          content: `Here you can view all your expired Job Posts, as well as the jobs you have archived manually or marked as 'Job Filled' from the Jobs Tab.<br><br>You can choose to reactivate archived jobs from this tab for the cost of a relevant Job Post Credit.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
      ],
      tourCallbacks: {
        onSkip: this.markTourAsSeen,
        onFinish: this.markTourAsSeen,
      },
    };
  },
  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || !employer.dashboardTours.archivedJobsTourSeen) {
      setTimeout(() => {
        try {
          this.$tours['archivedJobsTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markTourAsSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { archivedJobsTourSeen: true } });
    },
  },
};
</script>
<template>
  <v-tour name="archivedJobsTour" :steps="archivedJobsTourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
